@import "../theme/variables";

.category {
    border-radius: 4px;
    margin-bottom: 10px;
    height: 106px;

    a {
        color: #FFF !important;
        text-decoration: none !important; }

    .col-left {
        img {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            margin-left: 10px;
            height: 100px;
            width: 100px; } }

    .col-right {
        text-align: right;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding-right: 30px; } }
