$white: #fff;
$black: #000;
$blue: #007bff;
$primary: #2896d3;
$secondary: #6c757d;
$success: #0cbd65;
$green: #28a745;
$danger: #dc3545;
$warning: #e2c233;
$yellow: #ffc107;
$info: #17a2b8;
$light: #f8f9fa;
$dark: #343a40;
$gray: gray;
