.places-table {
    .places-table-results {
        margin-bottom: 20px; }

    .col-results {}

    .col-filter {}

    .col-results {
        @media screen and (max-width:767px) {
            margin-bottom: 20px; } }

    .pagination-container {
        .pagination {} } }

