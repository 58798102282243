.subcategories-container {
    margin: 10px 0 0 0; }

.place-modal {
    table {
        margin-top: -1px;

        ul {
            padding: 0;
            list-style: none; } } }


