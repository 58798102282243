@import "../theme/variables";

.clear {
    clear: both; }

.page-item.active .page-link {
    background-color: $primary;
    border-color: $primary; }

.dropdown-item.active,
.dropdown-item:active {
    background-color: $primary; }

.content {
    margin-top: 100px;
    min-height: 500px; }

.jumbotron.loading {
    background-color: #FFF !important; }

h1 {
    font-size: 24px;
    color: $white;

    @media screen and (max-width:767px) {
        font-size: 20px; } }

h2 {
    font-size: 16px;
    color: $light;

    @media screen and (max-width:767px) {
        font-size: 14px; } }
