@import "variables";

.btn-white {
  color: $black !important;
  background-color: $white !important; }

.btn-black {
  color: $white !important;
  background-color: $black !important; }

.btn-blue {
  color: $white !important;
  background-color: $blue !important; }

.btn-primary {
  color: $white !important;
  background-color: $primary !important; }

.btn-secondary {
  color: $white !important;
  background-color: $secondary !important; }

.btn-success {
  color: $white !important;
  background-color: $success !important; }

.btn-green {
  color: $white !important;
  background-color: $green !important; }

.btn-danger {
  color: $white !important;
  background-color: $danger !important; }

.btn-warning {
  color: $white !important;
  background-color: $warning !important; }

.btn-yellow {
  color: $white !important;
  background-color: $yellow !important; }

.btn-info {
  color: $white !important;
  background-color: $info !important; }

.btn-light {
  color: $white !important;
  background-color: $light !important; }

.btn-dark {
  color: $white !important;
  background-color: $dark !important; }

.btn-gray {
  color: $white !important;
  background-color: $gray !important; }
