@import "../theme/variables";

.carousel-page {
    .jumbotron {
        padding: 30px 20px;
        background-size: auto 100%;
        background-repeat: no-repeat;

        @media screen and (max-width:979px) {
            background-image: none !important; }

        h1 {
            text-align: right;
            color: $white;

            @media screen and (max-width:979px) {
                text-align: left; } }

        h2 {
            text-align: right;
            color: $light;
            width: 400px;
            float: right;

            @media screen and (max-width:979px) {
                width: inherit;
                float: inherit;
                text-align: left; } } }

    h5 {
        margin-top: 20px;
        font-size: 16px; } }
