@import "variables";

.text-white {
  color: $white !important; }

.text-black {
  color: $black !important; }

.text-blue {
  color: $blue !important; }

.text-primary {
  color: $primary !important; }

.text-secondary {
  color: $secondary !important; }

.text-success {
  color: $success !important; }

.text-green {
  color: $green !important; }

.text-danger {
  color: $danger !important; }

.text-warning {
  color: $warning !important; }

.text-yellow {
  color: $yellow !important; }

.text-info {
  color: $info !important; }

.text-light {
  color: $light !important; }

.text-dark {
  color: $dark !important; }

.text-gray {
  color: $gray !important; }
