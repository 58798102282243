@import "../theme/variables";

.notification-nav {
    border-radius: 4px;
    padding: 10px;
    text-align: center;

    @media screen and (max-width:979px) {
        margin-bottom: 20px; }

    &.has-n {
        border: 1px solid $danger;
        .badge {
            color: $white;
            border: 1px solid $danger;
            background-color: $danger; } }

    &.has-1 {
        border: 1px solid $blue;

        .badge {
            color: $white;
            border: 1px solid $blue;
            background-color: $blue; } }

    &.has-0 {
        .badge {
            display: none; } }

    .icon {
        margin: auto;

        img {
            width: 100px;
            margin-bottom: 10px; }

        .badge {
            position: absolute;
            margin: 20px 0 0 -40px;

            border-radius: 20px;
            font-size: 16px; } }

    .text {
        color: $white;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px; } }
