.subcategory {
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    height: 100px;
    overflow: hidden;

    a {
        color: #FFF !important;
        text-decoration: none !important; }

    .col-left {
        img {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            width: 100px;
            height: 100px; } }

    .col-right {
        text-align: right;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;
        align-items: center;
        padding-right: 30px;

        .subcategory_title {
            font-size: 15px; }

        .category_title {
            font-size: 13px; } } }
