.accept-cookies {
    position: fixed;
    bottom: 37px;
    left: 0;
    width: 100%;
    margin: 0 0 20px 0 !important;
    z-index: 999;
    border-radius: 0;
    transform: translateY(100%);
    transition: all 500ms ease-out;
    color: #111;
    background-color: #EEE;

    .show {
        opacity: 1;
        transform: translateY(0%);
        transition-delay: 1000ms; }


    a {
        text-decoration: underline;
        margin-left: 10px; }

    .acceptcookies {
        margin-left: 10px;
        vertical-align: baseline; } }
