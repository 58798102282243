@import "../theme/variables";

.home-page {
    .card.static-notifications {
        border: none;
        height: 405px;

        @media screen and (max-width:979px) {
            height: inherit; }

        .card-img-top {
            height: 253px;

            @media screen and (max-width:979px) {
                height: inherit; } }

        .card-title {
            color: $white; }

        .card-text {
            height: 100px;
            color: $light;
            overflow-y: auto;

            @media screen and (max-width:979px) {
                overflow: inherit;
                height: inherit; } }

        @media screen and (max-width:979px) {
            margin-bottom: 20px; } } }
