@import "../theme/variables";

.place {
    border-radius: 4px;
    margin-bottom: 10px;
    border: 0px solid $white;
    cursor: pointer;

    a {
        color: $white !important;
        text-decoration: none !important; }

    .col-left {
        img {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            width: 100px;
            height: 100px; } }

    .col-right {
        div {
            color: $white;
            text-align: right;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-end;

            padding-right: 10px;

            &.place_name {
                font-size: 15px;
                overflow: hidden;
                height: 48px; }

            &.subcategory_title {
                font-size: 13px;
                height: 23px;
                overflow: hidden; }

            &.distance {
                font-size: 12px; } } } }
