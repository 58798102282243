$blue-color:  #007bff;

.btn.btn-outline-success {
    color: #CCC;
    border-color: #CCC;
    padding: 5px 10px;
    font-size: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
        border-color: #CCC;
        background-color: #333; } }

.react-autosuggest__container {
    position: relative; }


.react-autosuggest__input {
    width: 100%;
    height: 36px;
    padding: 10px;
    border: 1px solid $blue-color;
    border-radius:  4px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    @media screen and (max-width:979px) {
        width: 160px; }

    @media screen and (max-width:767px) {
        width: 100%; } }


.react-autosuggest__input--focused {
    outline: none; }

.react-autosuggest__input--open {
    border-bottom-left-radius:  0;
    border-bottom-right-radius: 0; }

.react-autosuggest__suggestions-container {
    display: none; }

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 36px;
    width: 100%;
    min-width: 160px;
    margin-left: 1px;
    background-color: #FFFFFF;
    border-radius: 0 0 4px 4px;
    z-index: 2;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box; }

.react-autosuggest__suggestions-list {
    margin: 10x;
    padding: 0;
    list-style-type: none; }

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px; }

.react-autosuggest__suggestion--highlighted {
    background-color: $blue-color; }
