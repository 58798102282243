@import "../theme/variables";

.about-page {
    .jumbotron {
        padding: 30px 20px;
        background-size: auto 100%;
        background-repeat: no-repeat;

        @media screen and (max-width:979px) {
            background-image: none !important; }

        h1 {
            text-align: right;
            color: $white;

            @media screen and (max-width:979px) {
                text-align: left; } }

        h2 {
            text-align: right;
            color: $light;
            font-size: 22px; } } }


