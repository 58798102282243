@import "../theme/variables";

.redirect-browser {
    .btn {
        margin-left: 5px; }

    svg {
        font-size: 45px;
        color: $primary;
        margin: 10px 0 20px 0; } }


