@import "../theme/variables";

.home-carousel {
    &.loading {
        background-color: $light;
        height: 405px;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        * {
            color: $dark; } }

    h3 {
        font-size: 20px;
        color: $white; }

    p {
        color: $light; }

    .carousel-item {
        height: 405px; }

    .carousel-caption {
        height: 150px;
        width: 100%;
        left: inherit;
        right: inherit;
        bottom: 0;
        padding: 20px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        @media screen and (max-width:979px) {
            height: 180px; } } }
