.notifications-page {
    .notification-item {
        margin-bottom: 20px;

        img.type {
            width: 50px;
            float: right; }

        .title {
            height: 50px;
            overflow-y: auto;

            @media screen and (max-width:767px) {
                height: inherit;
                overflow: inherit; } }

        .intro {
            height: 100px;
            overflow-y: auto;

            @media screen and (max-width:767px) {
                height: inherit;
                overflow: inherit; } } } }
