.category-page {
    .jumbotron {
        padding: 20px;
        background-size: 80px auto;
        background-position: 10px;

        @media screen and (max-width:979px) {
            background-image: none !important; }

        h1 {
            text-align: right;
            color: #FFF;

            @media screen and (max-width:979px) {
                text-align: left; } }

        h2 {
            color: #FFF;
            font-weight: normal;
            margin-left: 400px;
            text-align: right;

            @media screen and (max-width:979px) {
                margin-left: 0;
                text-align: justify; } } } }
