.header {
    img.logo {
        max-width: 80px; }

    header {
        a {
            text-decoration: none;
            color: #00F;
            cursor: pointer;

            span {
                color: #00F; }

            &.selected {
                text-decoration: underline;
                font-weight: bold; } } }

    @media screen and (max-width:767px) {
        .form-inline {
            margin: 10px 0; } } }
