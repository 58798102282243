.subcategory-page {
    .jumbotron {
        padding: 20px;
        background-size: auto 100%;
        background-repeat: no-repeat;

        @media screen and (max-width:979px) {
            background-image: none !important; }

        h1 {
            text-align: right;
            color: #FFF;

            @media screen and (max-width:979px) {
                text-align: left; } }

        h2 {
            text-align: right;

            @media screen and (max-width:979px) {
                text-align: left; }

            a {
                color: #FFF !important;
                text-decoration: none;

                img {
                    width: 40px;
                    margin-right: 10px;

                    @media screen and (max-width:979px) {
                        display: none; } } } } } }
