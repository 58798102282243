.home-category {
        .card {
            height: 250px; }
        a {
            text-decoration: inherit !important; }

        img {
            margin: auto;
            display: block; }

        .card-img-top {
            width: 100px; }

        .card-title {
            text-align: center;
            color: #FFF; }

        .card-text {
            text-align: center;
            color: #FFF; } }
